import useDayjs from "@/composables/useDayjs";
import axios from "@/lib/axios";
import socket from "@/lib/socket";
import type { Market, MarketEntity } from "@/types/Market.js";
import type { Indice } from "@dev-team/types/data-provider/models/indice";
import type { Ticker } from "@dev-team/types/data-provider/models/ticker";
import { defineStore } from "pinia";
import type { ComputedRef } from "vue";
import { useMainStore } from ".";
import { MARKETS, useTickerStore } from "./ticker";

interface TickerDetail {
	marketCap?: number;
	previousPrice?: number; // previous day price
}

interface State {
	MARKETS: {
		key: string;
		name: string | ComputedRef<string>;
		indexCountries?: string[];
	}[];
	loadingCounter: number;
	markets: Market[] | null;
	selectedMarket: string | null;
	selectedEntity: MarketEntity | null;
	tickers: (Ticker & { details?: TickerDetail; detailsLoaded?: boolean })[];
	search: string;
	page: number;
	size: number;
	sort: string | undefined;
	asc: boolean;
	totalSize: number;
}

export const useForecastStore = defineStore("forecast", {
	state: (): State => ({
		MARKETS: MARKETS(),
		loadingCounter: 0,
		markets: null,
		selectedMarket: MARKETS()[0].key,
		selectedEntity: null,

		tickers: [],
		search: "",
		page: 1,
		size: 25,
		sort: undefined,
		asc: true,
		totalSize: 0,
	}),
	getters: {
		loading(state) {
			return state.loadingCounter > 0;
		},
	},
	actions: {
		async loadMarkets() {
			this.loadingCounter += 1;
			try {
				const {
					data: { indexes },
				} = await axios.get("/dataprovider/entities");

				const { data: indexTickers } = await axios.post(
					"/dataprovider/tickers",
					{
						symbols: indexes
							.map(({ ticker }: Indice) => ticker)
							.filter(Boolean),
					},
				);
				const tickerStore = useTickerStore();
				for (const ticker of indexTickers.list) {
					const details = await tickerStore.getTickerDetail(ticker.symbol);
					ticker.details = details;
				}
				this.markets = this.MARKETS.map((market) => {
					return {
						key: market.key,
						name: market.name,
						entities: indexes
							.map((index: Indice) => {
								if (!market.indexCountries?.includes(index.country as string))
									return null;
								const ticker = indexTickers.list.find(
									(ticker: Ticker) => ticker.symbol === index.ticker,
								);
								return {
									key: index.key,
									type: "index",
									price: ticker?.price,
									previousPrice: ticker?.details?.previousPrice,
								};
							})
							.filter(Boolean),
					};
				});
			} catch (e) {
				console.error(e);
			} finally {
				this.loadingCounter -= 1;
			}
		},
		async loadTickers() {
			this.loadingCounter += 1;
			const dayjs = useDayjs();
			try {
				const {
					selectedEntity,
					selectedMarket,
					page,
					size,
					sort,
					asc,
					search,
				} = this;

				const countries = this.MARKETS.find(
					(market) => market.key === selectedMarket,
				)?.indexCountries;

				let filter: { [key: string]: string[] } = {};
				if (selectedMarket === "FX") {
					filter = {
						domains: ["X"],
					};
				} else if (selectedMarket === "CR") {
					filter = {
						exchanges: ["CRYPTOCURRENCY"],
					};
				} else if (selectedEntity?.type === "index") {
					filter = {
						indexes: [selectedEntity.key],
					};
				} else if (selectedEntity?.type === "domain") {
					filter = {
						domains: [selectedEntity.key],
					};
				}
				const { data } = await axios.post("/dataprovider/available-tickers", {
					page,
					size,
					sort,
					asc,
					startDate: dayjs.value().subtract(1, "month").format("YYYY-MM-DD"),
					endDate: dayjs.value().format("YYYY-MM-DD"),
					countries,
					...filter,
					search,
				});

				// leave previous tickers live data
				this.tickers.forEach((ticker) => {
					socket.emit("leave", `PUBLIC:PRICE:${ticker.symbol}`);
				});

				this.tickers = data.list;
				this.totalSize = data.meta.totalSize;

				// load ticker details such as daily change and market cap
				// this doesn't need to be awaited
				const tickerStore = useTickerStore();
				this.tickers.forEach(async (ticker) => {
					const details = await tickerStore.getTickerDetail(ticker.symbol);
					ticker.details = details;
					ticker.detailsLoaded = true;
				});

				// listen live data
				this.tickers.forEach((ticker) => {
					socket.emit("join", `PUBLIC:PRICE:${ticker.symbol}`);
				});
			} catch (err) {
				console.error(err);
				if (err instanceof Error) {
					const mainStore = useMainStore();
					mainStore.addToastr({
						message: err.message,
					});
				}
			} finally {
				this.loadingCounter -= 1;
			}
		},
		async SOCKET_priceUpdate({
			symbol,
			price,
		}: {
			symbol: string;
			price: number;
		}) {
			const ticker = this.tickers.find((ticker) => ticker.symbol === symbol);
			if (ticker) {
				ticker.price = price;
			} else {
				socket.emit("leave", `PUBLIC:PRICE:${symbol}`);
			}
		},
	},
});
