import useDayjs from "@/composables/useDayjs";
import useTranslate from "@/composables/useTranslate";
import axios from "@/lib/axios";
import type { Country } from "@dev-team/types/data-provider/models/country";
import type { Currency } from "@dev-team/types/data-provider/models/currency";
import type { Exchange } from "@dev-team/types/data-provider/models/exchange";
import type { Indice } from "@dev-team/types/data-provider/models/indice";
import type { Record } from "@dev-team/types/data-provider/models/record";
import type { Sector } from "@dev-team/types/data-provider/models/sector";
import type { Ticker } from "@dev-team/types/data-provider/models/ticker";
import type { Domain } from "@dev-team/types/data-provider/models/ticker";

/* eslint-disable prefer-const */
import { defineStore } from "pinia";
import { type ComputedRef, toRef } from "vue";
import { useAuthStore, usePortfolioStore } from ".";

// biome-ignore format: keep the file short
export const EU_COUNTRY_CODES = ["AT", "BE", "BG", "CY", "CZ", "DE", "DK", "EE", "ES", "FI", "FR", "GB", "GR", "HR", "HU", "IE", "IT", "LT", "LU", "LV", "MT", "NL", "PL", "PT", "RO", "SE", "SI", "SK"];
// biome-ignore format: keep the file short
export const AS_COUNTRY_CODES = ["AE", "AF", "AM", "AZ", "BD", "BH", "BN", "BT", "CN", "CY", "GE", "HK", "ID", "IL", "IN", "IQ", "IR", "JO", "JP", "KG", "KH", "KP", "KR", "KW", "KZ", "LA", "LB", "LK", "MM", "MN", "MO", "MV", "MY", "NP", "OM", "PH", "PK", "QA", "SA", "SG", "SY", "TH", "TJ", "TL", "TM", "TW", "UZ", "VN", "YE"];

export const SECTOR_NAMES = {
	GIDA: {
		en: "FOOD",
		tr: "GIDA",
	},
	DOKUMA: {
		en: "TEXTILES",
		tr: "DOKUMA",
	},
	ORMAN: {
		en: "FORESTRY",
		tr: "ORMAN",
	},
	KAGIT: {
		en: "PAPER",
		tr: "KAGIT",
	},
	KIMYA: {
		en: "CHEMICALS",
		tr: "KIMYA",
	},
	TASTOPRAK: {
		en: "MINING AND QUARRYING",
		tr: "TASTOPRAK",
	},
	METALANA: {
		en: "BASIC METALS",
		tr: "METALANA",
	},
	METALESYA: {
		en: "FABRICATED METAL PRODUCTS",
		tr: "METALESYA",
	},
	ELEKTRIK: {
		en: "ELECTRICITY",
		tr: "ELEKTRIK",
	},
	TOPTAN: {
		en: "WHOLESALE TRADE",
		tr: "TOPTAN",
	},
	PERAKENDE: {
		en: "RETAIL TRADE",
		tr: "PERAKENDE",
	},
	LOKANTAOTEL: {
		en: "HOTELS AND RESTAURANTS",
		tr: "LOKANTAOTEL",
	},
	ULASTIRMA: {
		en: "TRANSPORTATION",
		tr: "ULASTIRMA",
	},
	EGITIM: {
		en: "EDUCATION",
		tr: "EGITIM",
	},
	BANKA: {
		en: "BANKS",
		tr: "BANKA",
	},
	SIGORTA: {
		en: "INSURANCE",
		tr: "SIGORTA",
	},
	FINANSAL: {
		en: "FINANCIAL SERVICES",
		tr: "FINANSAL",
	},
	HOLDING: {
		en: "HOLDING COMPANIES",
		tr: "HOLDING",
	},
	YATIRIMORTAKLIGI: {
		en: "INVESTMENT TRUST",
		tr: "YATIRIMORTAKLIGI",
	},
	ARACIKURUM: {
		en: "BROKERAGE HOUSES",
		tr: "ARACIKURUM",
	},
	YIYECEK: {
		en: "FOOD PRODUCTS",
		tr: "YIYECEK",
	},
	GAYRIMENKUL: {
		en: "REAL ESTATE",
		tr: "GAYRIMENKUL",
	},
	GMENKUL: {
		en: "REAL ESTATE",
		tr: "GAYRIMENKUL",
	},
	SPOR: {
		en: "SPORTS",
		tr: "SPOR",
	},
	DIGER: {
		en: "OTHERS",
		tr: "DIGER",
	},
	BILISIM: {
		en: "INFORMATION TECHNOLOGY",
		tr: "BILISIM",
	},
	TEKNOLOJI: {
		en: "TECHNOLOGY",
		tr: "TEKNOLOJI",
	},
	SORUSTURMA: {
		en: "SECURITY ACTIVITIES",
		tr: "SORUSTURMA",
	},
	INSAAT: {
		en: "CONSTRUCTION",
		tr: "INSAAT",
	},
	TARIM: {
		en: "AGRICULTURE",
		tr: "TARIM",
	},
	MADENCILIK: {
		en: "MINING",
		tr: "MADENCILIK",
	},
	IMALAT: {
		en: "MANUFACTURING",
		tr: "IMALAT",
	},
	LEASING: {
		en: "LEASING",
		tr: "LEASING",
	},
	GIRISIMSERMAYESI: {
		en: "VENTURE CAPITAL",
		tr: "GIRISIMSERMAYESI",
	},
	MESLEKI: {
		en: "PROFESSIONAL ACTIVITIES",
		tr: "MESLEKI",
	},
	TELEKOMUNIKASYON: {
		en: "TELECOMMUNICATIONS",
		tr: "TELEKOMUNIKASYON",
	},
	EGLENCE: {
		en: "ENTERTAINMENT",
		tr: "EGLENCE",
	},
	VARLIK: {
		en: "ASSET MANAGEMENT COMPANIES",
		tr: "VARLIK",
	},
	BILGI: {
		en: "INFORMATION",
		tr: "BILGI",
	},
	YAYIM: {
		en: "PUBLISHING ACTIVITIES",
		tr: "YAYIM",
	},
	SEYAHAT: {
		en: "TRAVEL AGENCY",
		tr: "SEYAHAT",
	},
	BUROYONETIM: {
		en: "OFFICE MANAGEMENT",
		tr: "BUROYONETIM",
	},
	FINANSMAN: {
		en: "FINANCING COMPANIES",
		tr: "FINANSMAN",
	},
};

interface MarketMeta {
	key: string;
	name: ComputedRef<string>;
	indexCountries?: string[];
	domains?: string[];
}
interface CryptoExchange {
	name: string;
	value: string;
}

interface DomainMeta {
	name: string;
	value: string;
	nameSingle?: string;
	countryFilter?: boolean;
	sectorFilter?: boolean;
}

export const MARKETS = (): MarketMeta[] => {
	const { t } = useTranslate();
	return [
		{
			key: "US",
			name: t("US"),
			indexCountries: ["US"],
		},
		{
			key: "EU",
			name: t("Europe"),
			indexCountries: EU_COUNTRY_CODES,
		},
		{
			key: "AS",
			name: t("Asia"),
			indexCountries: AS_COUNTRY_CODES,
		},
		{
			key: "TR",
			name: t("Turkey"),
			indexCountries: ["TR"],
			domains: ["F"],
		},
		{
			key: "FX",
			name: t("Exchange Rates"),
		},
		{ key: "CR", name: t("Crypto") },
	];
};

export const CRYPTO_EXCHANGES = (): CryptoExchange[] => {
	return [
		{
			name: "Binance",
			value: "BINANCE",
		},
		{
			name: "BTCTurk",
			value: "BTCTURK",
		},
		{
			name: "Paribu",
			value: "PARIBU",
		},
	];
};

export const DOMAINS = (): DomainMeta[] => {
	const { t } = useTranslate();
	return [
		{
			name: t("All").value,
			value: "all",
		},
		{
			name: t("Stocks").value,
			nameSingle: t("Stock").value,
			value: "E",
			countryFilter: true,
			sectorFilter: true,
		},
		{
			name: t("Funds").value,
			nameSingle: t("Fund").value,
			value: "F",
			countryFilter: true,
		},
		{
			name: t("Futures").value,
			nameSingle: t("Future").value,
			value: "FT",
			countryFilter: true,
		},
		{
			name: t("Forex").value,
			nameSingle: t("Forex").value,
			value: "X",
		},
		{
			name: t("Crypto").value,
			nameSingle: t("Crypto").value,
			value: "CR",
		},
		{
			name: t("Indices").value,
			nameSingle: t("Indice").value,
			value: "I",
			countryFilter: true,
		},
	];
};

interface State {
	loading: number;

	search: string;
	selectedSectors: string[];
	selectedIndexes: string[];
	selectedExchanges: string[];
	selectedDomains: string[];
	selectedMarket: string;

	page: number;
	size: number;
	sort: string | undefined;
	asc: boolean;
	totalSize: number;

	tickers: Ticker[];
	indexes: Indice[];
	sectors: Sector[];
	exchanges: Exchange[];
	domains: Domain[];
	countries: Country[];
	currencies: Currency[];

	MARKETS: MarketMeta[];
}

export const useTickerStore = defineStore("ticker", {
	state: (): State => ({
		loading: 0,

		search: "",
		selectedSectors: [],
		selectedIndexes: [],
		selectedExchanges: [],
		selectedDomains: [],
		selectedMarket: MARKETS()[0].key,

		page: 1,
		size: 50,
		sort: undefined,
		asc: true,
		totalSize: 0,

		tickers: [],
		indexes: [],
		sectors: [],
		exchanges: [],
		domains: [],
		countries: [],
		currencies: [],

		MARKETS: MARKETS(),
	}),

	getters: {
		userAllowedIndexes(state) {
			const authStore = useAuthStore();
			if (!authStore.userPlan) return [];

			return state.indexes.filter((index) =>
				authStore.userPlan?.details.tickerIndexes.includes(index.key),
			);
		},
	},

	actions: {
		async loadEntities() {
			this.loading += 1;
			try {
				let {
					data: { sectors, indices, exchanges, domains, countries, currencies },
				} = await axios.get("/dataprovider/entities");
				indices = await Promise.all(
					indices.map(async (index: Indice) => {
						const details = await this.getTickerDetail(index.ticker);
						return {
							...index,
							...details,
						};
					}),
				);
				this.$patch({
					sectors,
					indexes: indices,
					exchanges,
					domains,
					countries,
					currencies,
				});
			} catch (err) {
				console.error(err);
			}
			this.loading -= 1;
		},

		/**
		 * loadTickers
		 * @param {Object} options Options object for loadTickers function.
		 * @param {boolean} options.mergeTickers If true, merge tickers with existing ones.
		 * @returns {Promise<{
		 *  list: Array<Ticker>,
		 *  meta: {
		 *    totalSize: number,
		 *    totalPage: number
		 *  }
		 * }>} tickers and meta data
		 */
		async loadTickers({
			mergeTickers = false,
			stats,
		}: {
			mergeTickers: boolean;
			stats?: any;
		}) {
			this.loading += 1;

			try {
				const {
					selectedIndexes: indexes,
					selectedSectors: sectors,
					selectedExchanges: exchanges,
					selectedDomains: domains,
					selectedMarket,
					page,
					size,
					sort,
					asc,
					search,
				} = this;

				const countries = this.MARKETS.find(
					(market) => market.key === selectedMarket,
				)?.indexCountries;

				const { data } = await axios.post("/dataprovider/available-tickers", {
					page,
					size,
					sort,
					asc,
					indexes: indexes.length ? indexes : undefined,
					sectors: sectors.length ? sectors : undefined,
					exchanges: exchanges.length ? exchanges : undefined,
					domains: domains.length ? domains : undefined,
					countries: countries ? countries : undefined,
					search,
					stats,
				});

				if (mergeTickers) {
					this.tickers.push(...data.list);
				} else {
					this.tickers = data.list;
				}
				this.loading -= 1;
				this.totalSize = data.meta.totalSize;

				return data;
			} catch (err) {
				console.error(err);
				this.loading -= 1;
			}
		},

		async loadNextPage() {
			try {
				this.page++;

				const data = await this.loadTickers({
					mergeTickers: true,
				});

				if (!data.list.length) {
					this.page--;
				}
			} catch (e) {
				console.error(e);
			}
		},

		async fetchLatestPricesOf(symbols: string[]) {
			if (!symbols.length) {
				return {};
			}
			const { data } = await axios.post("/dataprovider/tickers", {
				symbols,
			});
			return Object.fromEntries(
				data.list.map(
					({ symbol, price }: { symbol: string; price: number }) => [
						symbol,
						price,
					],
				),
			);
		},

		async fetchHistoricalPriceData({
			symbols,
			start,
			end,
			currency,
		}: {
			symbols: string[];
			start: string;
			end: string;
			currency: string;
		}) {
			const { data } = await axios.post<{ [symbol: string]: Record[] }>(
				"/dataprovider/prices",
				{ symbols, start, end, currency },
			);
			return data;
		},

		async getTickerDetail(symbol: string) {
			const dayjs = useDayjs();
			const historical = (
				await axios.post<{ [symbol: string]: Record[] }>(
					"/dataprovider/prices",
					{
						symbols: [symbol],
						start: dayjs.value().subtract(1, "week").format("YYYY-MM-DD"),
						end: dayjs.value().format("YYYY-MM-DD"),
					},
				)
			).data[symbol];

			const data: {
				price?: number;
				previousPrice?: number;
			} = {
				price: undefined,
				previousPrice: undefined,
			};
			if (historical && historical.length > 1) {
				data.price = historical[0].price;
				for (const record of historical.slice(1)) {
					const diff = Math.abs(
						dayjs.value(record.time).diff(dayjs.value(), "day"),
					);
					if (diff > 0) {
						data.previousPrice = record.price;
						break;
					}
				}
			}
			return data;
		},

		RESET_STATE() {
			this.$patch({
				search: "",
				selectedSectors: [],
				selectedIndexes: [],
				selectedExchanges: [],
				selectedDomains: [],
			});
		},
	},
});
