import axios from "@/lib/axios";
import { clone } from "@/lib/utils.js";
import type { Notification } from "@dev-team/types/rest-api/models/Notification";
import { defineStore } from "pinia";

interface ListParams {
	page: number;
	size: number;
	search: string | null;
	sort: string;
	asc: boolean;
}
interface State {
	listLoading: boolean;
	listRecords: Notification[];
	listCount: number;
	listParams: ListParams;
}
const DEFAULT_LIST_PARAMS: ListParams = {
	page: 1,
	size: 10,
	search: null,
	sort: "createdAt",
	asc: false,
};

export const useNotificationStore = defineStore("notification", {
	state: (): State => ({
		listLoading: false,
		listRecords: [],
		listCount: 0,
		listParams: clone(DEFAULT_LIST_PARAMS),
	}),

	actions: {
		async fetchList() {
			this.listLoading = true;
			try {
				const params = Object.assign({}, DEFAULT_LIST_PARAMS, this.listParams);
				params.asc = !!Number(params.asc);
				const {
					data: { records, count },
				} = await axios.get("/notification/list", { params });
				this.listRecords = records;
				this.listCount = count;
				this.listLoading = false;
			} catch (err) {
				this.listLoading = false;
				throw err;
			}
		},

		async deleteById(id: string) {
			await axios.delete(`/notification/${id}`);
			this.listRecords = this.listRecords.filter((i) => i._id !== id);
			this.listCount = this.listCount - 1;
		},

		async deleteAll() {
			await axios.delete("/notification");
			this.listRecords = [];
			this.listCount = 0;
		},

		SOCKET_newNotification(record: Notification) {
			this.listRecords.unshift(record);
			this.listCount++;
			const audio = new Audio("/audio/notification.mp3");
			audio.play().catch(() => false);
		},
	},
});
