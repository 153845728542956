import axios from "@/lib/axios";
import type { Plan } from "@dev-team/types/rest-api/models/Plan";
import type {
	Subscription,
	User,
	UserStatus,
} from "@dev-team/types/rest-api/models/User";
import { defineStore } from "pinia";

interface State {
	loading: boolean;
	users: User[];
	userPagination: {
		search: string;
		page: number;
		limit: number;
	};
	totalUsers: number;
	plans: (Plan & { userCount: number; updatedAt: Date })[];
	stats: any;
}

interface UserModel {
	firstname: string;
	lastname: string;
	email: string;
	password: string;
	status: UserStatus;
	subscription: Subscription;
}

type PlanModel = Plan;

export const useAdministrationStore = defineStore("administration", {
	state: (): State => ({
		loading: false,
		users: [],
		userPagination: {
			search: "",
			page: 1,
			limit: 10,
		},
		totalUsers: 0,

		plans: [] as (Plan & { userCount: number; updatedAt: Date })[],
		stats: null,
	}),
	getters: {
		totalPlans: (state) => state.plans?.length,
	},
	actions: {
		async loadSystemUsers() {
			this.loading = true;
			try {
				const res = await axios.get("/admin/users", {
					params: this.userPagination,
				});
				this.users = res.data.list;
				this.totalUsers = res.data.meta.totalSize;
			} catch (e) {
				//TODO: do smt here
			} finally {
				this.loading = false;
			}
		},
		async loadPlans() {
			const res = await axios.get("/admin/plans");
			this.plans = res.data;
		},
		async createUser({ userModel }: { userModel: UserModel }) {
			const result = await axios.post("/admin/users", userModel);
			if (result.status === 200) {
				this.loadSystemUsers();
			}
			return result;
		},
		async createPlan({ planModel }: { planModel: PlanModel }) {
			const result = await axios.post("/admin/plans", planModel);
			if (result.status === 200) {
				this.loadPlans();
			}
			return result;
		},
		async updateUser({ id, userModel }: { id: string; userModel: UserModel }) {
			const result = await axios.put(`/admin/users/${id}`, userModel);
			if (result.status === 200) {
				this.loadSystemUsers();
			}
			return result;
		},
		async updatePlan({ id, planModel }: { id: string; planModel: PlanModel }) {
			const result = await axios.put(`/admin/plans/${id}`, planModel);
			if (result.status === 200) {
				this.loadPlans();
			}
			return result;
		},
		async deleteUser({ _id }: { _id: string }) {
			const result = await axios.delete(`/admin/users/${_id}`);

			if (result.status === 200) {
				this.loadSystemUsers();
			}
			return result;
		},
		async deletePlan({ _id }: { _id: string }) {
			const result = await axios.delete(`/admin/plans/${_id}`);
			if (result.status === 200) {
				this.loadPlans();
			}
			return result;
		},
		async getStatistics(integration = "Matriks") {
			const res = await axios.get("/admin/statistics/", {
				params: { integration: integration },
			});
			if (res.status === 200) {
				this.stats = res.data;
			}
			return res;
		},
	},
});
