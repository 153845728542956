import axios from "@/lib/axios";
import { clone, resetObjectRecursive } from "@/lib/utils";
import { Asset } from "@dev-team/types/rest-api/models/schemas/Asset";
import { defineStore } from "pinia";

interface State {
	messages: Message[];
	waitingResponse: boolean;
	portfolioQuestions: PortfolioQuestion[];
	portfolioQuestionData: PortfolioQuestionData;
}

export interface PortfolioQuestion {
	id: number;
	language: string;
	question: string;
}
export interface Message {
	id: number;
	text: string;
	isQuestion: boolean;
}

export interface PortfolioQuestionData {
	question_id: number | undefined;
	query: string | undefined;
	language: string | undefined;
	portfolioName: string | undefined;
	portfolioTickers: string[];
	portfolioAssets: Pick<Asset, "symbol" | "price" | "amount">[] | undefined;
	portfolioHistoricalPrice: { [key: string]: number } | undefined;
}

const DEFAULT_PORTFOLIO_QUESTION_DATA = {
	question_id: undefined,
	query: undefined,
	language: undefined,
	portfolioName: undefined,
	portfolioAssets: undefined,
	portfolioHistoricalPrice: undefined,
	portfolioTickers: [],
};

export const useChatbotStore = defineStore("chatbot", {
	state: (): State => ({
		messages: [],
		waitingResponse: false,
		portfolioQuestions: [],
		portfolioQuestionData: clone(DEFAULT_PORTFOLIO_QUESTION_DATA),
	}),
	actions: {
		async getStreamResponse(
			query: string,
			msgId: number,
			portfolioObj?: Omit<PortfolioQuestionData, "query">,
		) {
			await axios
				.post(
					`/chatbot/${portfolioObj ? "portfolio-response" : "response"}`,
					{
						...(portfolioObj
							? {
									...portfolioObj,
								}
							: { query: query }),
						stream: true,
					},
					{
						headers: {
							Accept: "text/event-stream",
						},
						responseType: "stream",
						adapter: "fetch",
						timeout: 180000,
					},
				)
				.then(async (response) => {
					const stream = response.data;

					// consume response
					const reader = stream
						.pipeThrough(new TextDecoderStream())
						.getReader();
					while (true) {
						const { value, done } = await reader.read();
						if (done) break;
						if (value) {
							for (let i = 0; i < this.messages.length; i++) {
								if (this.messages[i].id === msgId) {
									this.messages[i].text += value;
								}
							}
						}
					}
				});
		},
		async loadPortfolioQuestions(language: string) {
			const {
				data: { questions },
			} = await axios.post("chatbot/portfolio_questions", {
				language,
			});
			this.portfolioQuestions = questions;
		},
		RESET_FORM_STATE() {
			resetObjectRecursive(
				this.portfolioQuestionData,
				DEFAULT_PORTFOLIO_QUESTION_DATA,
			);
		},
	},
});
