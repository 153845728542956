import {
	DEFAULT_LANG,
	type Language,
	isLang,
} from "@/composables/useTranslate";
import useTranslate from "@/composables/useTranslate";
import axios from "@/lib/axios";
import socket from "@/lib/socket";
import type { User } from "@dev-team/types/rest-api/models/User";
import { defineStore } from "pinia";
import { useMainStore } from ".";

interface State {
	setLanguageLoading: boolean;
	loginError: any;
	accountBlocked: boolean;
	loginLoading: boolean;
	sendMailLoading: boolean;
	sendMailError: any;
	recoverPasswordError: any;
	recoverPasswordLoading: boolean;
	passwordUpdateDialog: boolean;
	updatePasswordError: any;
	user?: User | null;
	socketStatus: "disconnect" | "connect" | "connect_error";
	accessToken: string | null;
	refreshToken: string | null;
}
export const useAuthStore = defineStore("auth", {
	state: (): State => ({
		setLanguageLoading: false,
		loginError: null,
		accountBlocked: false,
		loginLoading: false,
		sendMailLoading: false,
		sendMailError: null,
		recoverPasswordError: null,
		recoverPasswordLoading: false,
		passwordUpdateDialog: false,
		updatePasswordError: null,
		user: null,
		// "connect", "disconnect", "connect_error"
		socketStatus: "disconnect",
		accessToken: localStorage.getItem("accessToken"),
		refreshToken: localStorage.getItem("refreshToken"),
	}),

	getters: {
		isLoggedin: (state) => Boolean(state.user),
		isAdmin: (state) => state.user && state.user.role === "admin",
		userPlan: (state) => state.user?.subscription?.plan,
		language: (state): Language => {
			const stateLang = state.user?.language;
			const localStorageLang = window.localStorage.getItem("language");
			const browserLang = navigator.language?.toLowerCase().slice(0, 2);

			if (stateLang) {
				window.localStorage.setItem("language", stateLang);
				return stateLang as Language;
			}
			if (localStorageLang && isLang(localStorageLang)) {
				return localStorageLang;
			}
			if (browserLang && isLang(browserLang)) {
				return browserLang;
			}
			return DEFAULT_LANG;
		},
		socketConnection: (state) => state.socketStatus === "connect",
	},

	actions: {
		async loadUser() {
			const { data } = await axios.get("/auth/user");
			this.$patch({ user: data });
			socket.io.opts.extraHeaders = {
				Authorization: `Bearer ${this.accessToken}`,
			};
			if (!socket.connected) {
				socket.connect();
			}
			return data;
		},

		async updateUser({
			firstname,
			lastname,
			email,
			approvedConsentText,
		}: {
			firstname: string;
			lastname: string;
			email: string;
			approvedConsentText: boolean;
		}) {
			const profileData = {
				...this.user,
				firstname,
				lastname,
				email,
				approvedConsentText,
			};
			const res = await axios.put("/auth/profile", profileData);
			await this.loadUser();
			return res;
		},

		async sendRecoverPasswordMail(email: string) {
			let success = false;
			this.sendMailLoading = true;
			this.sendMailError = null;

			try {
				const { data } = await axios.post("/auth/send-reset-password-link", {
					email,
				});

				const { user, isMailSend } = data;

				if (isMailSend) {
					this.user = user;
					success = true;
				} else {
					success = false;
				}
			} catch (err) {
				this.sendMailError = err;
				success = false;
			}

			this.sendMailLoading = false;
			return success;
		},

		async recoverPassword(token: string, newPassword: string) {
			let success = false;
			this.recoverPasswordLoading = true;
			this.recoverPasswordError = null;

			try {
				const { data } = await axios.put("/auth/recover-password", {
					token,
					newPassword,
				});

				const { status } = data;

				if (status) {
					success = true;
				} else {
					success = false;
				}
			} catch (err) {
				this.recoverPasswordError = (err as any).fields[0];

				success = false;
			}

			this.recoverPasswordLoading = false;
			return success;
		},

		async login({
			email,
			password,
			rememberMe,
		}: {
			email: string;
			password: string;
			rememberMe: boolean;
		}) {
			let success = false;
			this.loginLoading = true;
			this.loginError = null;

			try {
				const { data } = await axios.post("/auth/login", {
					email,
					password,
					rememberMe,
				});

				const { accessToken, refreshToken, user } = data;

				if (accessToken && refreshToken) {
					this.setAccessToken(accessToken);
					this.setRefreshToken(refreshToken);
					this.user = user;
					socket.connect();
					success = true;
				} else {
					success = false;
				}
			} catch (err) {
				this.loginError = err;
				success = false;
			}

			this.loginLoading = false;
			return success;
		},

		async refreshAccessToken() {
			const { data } = await axios.post("/auth/refresh", {
				refreshToken: this.refreshToken,
			});
			const { accessToken, refreshToken } = data;
			this.setAccessToken(accessToken);
			if (refreshToken) {
				this.setRefreshToken(refreshToken);
			}
			return { accessToken, refreshToken };
		},

		async loginWithSSO({
			token,
			provider,
			email,
			approvedConsentText,
		}: {
			token: string;
			provider: string;
			email: string;
			approvedConsentText: boolean;
		}) {
			const { data } = await axios.post("/auth/sso", {
				token,
				provider,
				email,
				approvedConsentText,
			});
			const { accessToken, refreshToken, user } = data;
			this.setAccessToken(accessToken);
			this.setRefreshToken(refreshToken);
			this.user = user;
			socket.connect();
			return data;
		},

		async logout() {
			socket.disconnect();
			this.accessToken = null;
			this.refreshToken = null;
			localStorage.removeItem("accessToken");
			localStorage.removeItem("refreshToken");
			this.user = null;
		},

		async injectUser(user: User, accessToken: string, refreshToken: string) {
			socket.disconnect();
			this.user = user;
			this.setAccessToken(accessToken);
			this.setRefreshToken(refreshToken);
			socket.connect();
		},

		async updatePassword({
			currentPassword,
			newPassword,
			newPasswordRepeat,
		}: {
			currentPassword: string;
			newPassword: string;
			newPasswordRepeat: string;
		}) {
			return axios.put("/auth/profile/password", {
				currentPassword,
				newPassword,
				newPasswordRepeat,
			});
		},

		async changeLanguage({ language = DEFAULT_LANG }) {
			const mainStore = useMainStore();
			const { t } = useTranslate();
			this.setLanguageLoading = true;
			window.localStorage.setItem("language", language);
			if (!this.isLoggedin) return;
			const res = await axios.put("/auth/profile/language", { language });
			await this.loadUser();
			this.setLanguageLoading = false;
			mainStore.addToastr({
				message: t("Language changed").value,
				type: "success",
				timeout: 2000,
			});

			return res;
		},

		setAccessToken(val: string) {
			localStorage.setItem("accessToken", val);
			this.accessToken = val;
			socket.io.opts.extraHeaders = { Authorization: `Bearer ${val}` };
		},
		setRefreshToken(val: string) {
			localStorage.setItem("refreshToken", val);
			this.refreshToken = val;
		},

		SOCKET_connect() {
			if (this.socketStatus === "connect_error") {
				// const mainStore = useMainStore();
				// const { t } = useTranslate();
				// mainStore.addToastr({
				//   message: t("Server connection lost. Please refresh the page.").value,
				//   type: "error",
				//   timeout: 10000,
				// });
			}
			this.socketStatus = "connect";
		},
		SOCKET_disconnect(reason: string) {
			if (reason === "io client disconnect") {
				this.socketStatus = "disconnect";
			} else {
				this.socketStatus = "connect_error";
			}
		},
	},
});
